import React from 'react'
import { shape, arrayOf, string, number, object } from 'prop-types'
import { Grid, Button, Icon, Card, Label } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Layout from 'components/Layout'

dayjs.extend(relativeTime)

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`
  const title = get(data, 'site.siteMetadata.title')

  return (
    <Layout location={location}>
      <Helmet title={`${title} - With tag "${tag}"`} />
      <Grid style={{ margin: 0 }} className="ep-padding-12" centered>
        <Grid.Column mobile={16} tablet={12} computer={10}>
          <div className="ep-space-between">
            <Link to="/">
              <Button basic icon labelPosition="left">
                <Button.Content visible>Home</Button.Content>
                <Icon name="home" />
              </Button>
            </Link>
            <Link to="/tags">
              <Button basic icon labelPosition="right">
                <Button.Content visible>All tags</Button.Content>
                <Icon name="left arrow" />
              </Button>
            </Link>
          </div>
          <Card style={{ width: 310 }} raised className="ep-mb-section">
            <Card.Content>
              <Card.Header>Posts</Card.Header>
              <Card.Meta>{tagHeader}</Card.Meta>
              <Card.Description>
                {edges.map(({ node }) => {
                  const { title, date } = node.frontmatter
                  const { slug } = node.fields

                  return (
                    <Label.Group key={slug} size="large">
                      <Link to={slug}>
                        <Label color="grey" style={{ margin: '0 8px 8px 0' }}>
                          {title}
                          <Label.Detail>
                            {dayjs(new Date(date)).format('DD-MM-YYYY')}
                          </Label.Detail>
                        </Label>
                      </Link>
                    </Label.Group>
                  )
                })}
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </Layout>
  )
}

Tags.propTypes = {
  location: object,
  pageContext: shape({
    tag: string.isRequired
  }),
  data: shape({
    site: shape({
      siteMetadata: shape({
        title: string.isRequired
      })
    }),
    allMarkdownRemark: shape({
      totalCount: number.isRequired,
      edges: arrayOf(
        shape({
          node: shape({
            frontmatter: shape({
              title: string.isRequired,
              date: string.isRequired
            }),
            fields: shape({
              slug: string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
}

export default Tags

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
